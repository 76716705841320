import React, { useState } from 'react'
import './menu.css'
import popUp1 from '../media/PopUpMenu.png'
import popUp2 from '../media/PopUpMenu2.png'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const Menu = () => {
  const [state, setState] = useState('MenuPopUp1')
  const [state2, setState2] = useState('MenuPopUp2')

  const redirect = (place) => {
    window.location = window.location.origin + '/' + place
  }

  const showPopUp1 = () => {
    setState('MenuPopUp1v')
    setTimeout(() => {
      setState('MenuPopUp1')
    }, 4000);
  }

  const showPopUp2 = () => {
    setState2('MenuPopUp2v')
    setTimeout(() => {
      setState2('MenuPopUp2')
    }, 4000);
  }

  const certificate = () => {
    const finishDate = (new Date('Jul 31, 2021 00:00:00').getTime())
    const currentTime = new Date().getTime();
    const distanceToDate = finishDate - currentTime;
    if (Math.floor((distanceToDate % (1000 * 60)) / 1000) <= 0) {
      redirect('certificate')
    } else {
      showPopUp2()
    }
  }

  const memories = () => {
    const finishDate = new Date('Jul 30, 2021 00:00:00').getTime()
    const currentTime = new Date().getTime();
    const distanceToDate = finishDate - currentTime;
    if (Math.floor((distanceToDate % (1000 * 60)) / 1000) <= 0) {
      redirect('memorias')
    } else {
      showPopUp1()
    }
  }

  const logOut = () => {
    cookies.remove('user', { path: '/' })
    redirect('login')
  }

  return (
    <div className='MenuContainer'>
      <div className='MenuItem yellowColor' onClick={() => redirect('room')}>
        <h4>CONFERENCIAS</h4>
      </div>
      <p className='MenuSeparator yellowColor'>|</p>
      <div className='MenuItem yellowColor' onClick={() => redirect('agenda')}>
        <h4>AGENDA</h4>
      </div>
      <p className='MenuSeparator yellowColor'>|</p>
      <div className='MenuItem yellowColor' onClick={memories}>
        <h4>MEMORIAS</h4>
        <img src={popUp2} alt="" className={state} />
      </div>
      <p className='MenuSeparator yellowColor'>|</p>
      <div className='MenuItem yellowColor' onClick={() => redirect('speakers')}>
        <h4>PONENTES</h4>
      </div>
      <p className='MenuSeparator yellowColor'>|</p>
      <div className='MenuItem yellowColor' onClick={certificate}>
        <h4>CERTIFICADO<br />DE ASISTENCIA</h4>
        <img src={popUp1} alt="" className={state2} />
      </div>
      <p className='MenuSeparator yellowColor'>|</p>
      <div className='MenuItem yellowColor' onClick={logOut}>
        <h4>CERRAR<br />SESIÓN</h4>
      </div>
    </div>
  )
}

export default Menu