import { useState, useEffect } from "react"
import { API } from '../../../config'
import SlideCheck from '../../../components/slideCheck/slideCheck'
import './memoriasAdmin.css'

let MemoriasAdmin = (props) => {
  const [btnSel, setbtnSel] = useState('')
  const [iframe, setIframe] = useState({})
  const [textJs, setTextJs] = useState('')
  const [textHtml, setTextHtml] = useState('')
  const [language, setLanguage] = useState(false)

  const listMem = {
    dia1: [
      "D1-C1",
      "D1-C2",
      "D1-C3",
      "D1-C4",
    ],
    dia2: [
      "D2-C1",
      "D2-C2",
      "D2-C3",
      "D2-C4",
    ],
    dia3: [
      "D3-C1",
      "D3-C2",
      "D3-C3",
      "D3-C4",
    ],
  }

  useEffect(() => {

    if (textJs && textJs[1] === 's') {
      let iframeSplit = ''
      let iframeFinal = {}
      try {
        iframeSplit = textJs.split('(')
        iframeSplit.pop()
        iframeSplit = iframeSplit[iframeSplit.length - 1]
        iframeSplit = iframeSplit.split(',')

        let regexS = /'|"/gi;

        let p = iframeSplit[0].replace(regexS, '');
        let c = iframeSplit[1].replace(regexS, '');
        let q = iframeSplit[2].split('+')[0].replace(regexS, '');

        c = c.replace(/ /gi, '')

        iframeFinal = {
          p: p,
          c: c,
          q: q
        }

      }
      catch (err) {
        console.log(err);
      }
      setIframe(iframeFinal)
    }

    return () => {
      // Unmount
    }
  }, [textJs, textHtml])

  let btnSelected = (evt, btn) => {
    let allBtn = document.getElementsByClassName('btn')

    for (let i = 0; i < allBtn.length; i++) {
      if (allBtn[i].classList[2] !== undefined) {
        allBtn[i].className = `btn ${btn}`
      }
    }

    evt.className = `btn ${btn} selected`

    setbtnSel(btn)
  }

  let handleClickSendJs = (evt) => {
    let id = iframe.p + iframe.c

    let iframeJS = `<iframe id=${id || ''} src="https://boxcast.tv/view-embed/${iframe.c}?${iframe.q}" frameBorder="0" scrolling="auto" allow="autoplay; fullscreen"></iframe>`
    let conf = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        settings: iframeJS,
      }),
    };

    fetch(`${API}settings/${btnSel}_${language ? "portuguese" : "spanish"}`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);

        setTextHtml('')
        setTextJs('')
      })
  }

  let handleClickSendHtml = (evt) => {
    let conf = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        settings: textHtml,
      }),
    };

    fetch(`${API}settings/${btnSel}_${language ? "portuguese" : "spanish"}`, conf)
      .then(res => res.json())
      .then(res => {
        console.log(res);

        setTextJs('')
        setTextHtml('')
      })
  }

  return (
    <div className="MemoriasAdminCont">
      <h1>Memorias</h1>
      <div className="slideCheckCont">
        <h2 className={!language ? "selected" : "none"}>Español</h2>
        <SlideCheck state={{ slideCheck: [language, setLanguage] }}/>
        <h2 className={language ? "selected" : "none"}>português</h2>
      </div>
      <div className="container">
        <div className="dayCont1">
          <h2>Dia 1</h2>
          <div className="btnCont">
            {
              listMem.dia1.map((ele, idx) => {
                return (
                  <button className={`btn ${ele}`} key={idx} onClick={e => {btnSelected(e.target, ele)}}>Iframe {idx + 1}</button>
                )
              })
            }
          </div>
        </div>
        <div className="dayCont2">
          <h2>Dia 2</h2>
          <div className="btnCont">
            {
              listMem.dia2.map((ele, idx) => {
                return (
                  <button className={`btn ${ele}`} key={idx} onClick={e => {btnSelected(e.target, ele)}}>Iframe {idx + 1}</button>
                )
              })
            }
          </div>
        </div>
        <div className="dayCont3">
          <h2>Dia 3</h2>
          <div className="btnCont">
            {
              listMem.dia3.map((ele, idx) => {
                return (
                  <button className={`btn ${ele}`} key={idx} onClick={e => {btnSelected(e.target, ele)}}>Iframe {idx + 1}</button>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="inpustCont">
        <div className="input">
          <input value={textHtml} type="text" id="iframeHtml" placeholder='Ej: <iframe src="https://www.company.com" allowfullscreen></iframe>' onChange={e => { setTextHtml(e.target.value) }} />
        </div>

        <div className="input">
          {iframe === 'error' && <h2>Ingresa un codigo valido</h2>}
          <input value={textJs} type="text" id="iframeJs" placeholder='Ingrese el codigo javascript' onChange={e => { setTextJs(e.target.value) }} />
        </div>

        <div className="frame">
          {
            textHtml
              ? <div className="frameCont" dangerouslySetInnerHTML={{ __html: textHtml }}></div>
              : <iframe id={iframe.p + iframe.c || ""} src={`https://boxcast.tv/view-embed/${iframe.c}?${iframe.q}`} frameBorder="0" scrolling="auto" allow="autoplay; fullscreen"></iframe>
          }
        </div>

        <div className="actions">
          <button className="btnActions sendHtml" onClick={e => { handleClickSendJs(e) }}>Enviar Js</button>
          <button className="btnActions sendJs" onClick={e => { handleClickSendHtml(e) }}>Enviar html</button>
        </div>
      </div>
    </div>
  )
}

export default MemoriasAdmin
