import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import './register.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import axios from 'axios'
import { API } from '../../config'
import PopUp1 from '../../media/PopUpRegistro.png'
import sendArrow from '../../media/registerArrow.png'

const Register = ({ history }) => {
  const [user, setUser] = useState({ name: '', primaryLastname: '', secondLastname: '', email: '', speciality: '', country: '' })
  const [checks, setChecks] = useState({ policy: false, terms: false, info: false })
  const [popUpRegister, setPopUpRegister] = useState(false)

  const onChangeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const register = {}
    for (const key in user) {
      if (user.hasOwnProperty.call(user, key)) {
        const element = user[key]
        if (element.trim() === '') {
          return
        }
        if (key === 'email') {
          register[key] = element.trim().toLowerCase()
        } else {
          register[key] = element.trim()
        }
      }
    }
    if (!checks.policy || !checks.terms) {
      return
    }
    register.receiveAccept = checks.info

    const options = {
      data: register,
      method: 'POST',
      url: API + "register",
      headers: { "Content-Type": "application/json" }
    }
    try {
      await axios.request(options)
      history.push('/login')
    } catch (error) {
      setPopUpRegister(true)
      setTimeout(() => {
        setPopUpRegister(false)
      }, 4000);
      setUser({ ...user, email: "" })
      console.log('USUARIO YA REGISTRADO');
    }
  }

  document.title = 'CENTRA - Registro'

  return (
    <div className='RegisterContainer'>
      <header className='RegisterHeader'>
        <img src={Logo1} alt="FELANPE" />
        <img src={Logo2} alt="Abbott" />
      </header>
      <form className='RegisterContent' onSubmit={onSubmit}>
        <img src={LogoCentra} alt="Centra" className='RegisterCentralLogo' />
        <div className='RegisterInputs'>
          {
            popUpRegister &&
            <div className="popUpregisterYet-open" onClick={e => {setPopUpRegister(false)}}></div>
          }
          <div className='RegisterContInput'>
            <div className='gradientBackground'>
              <label htmlFor="" className='centraColor bold'>NOMBRE</label>
            </div>
            <input type="text" name='name' value={user.name} onChange={onChangeHandler} required id='name' />
            <img src={PopUp1} alt="" className='RegisterPopUp1' />
          </div>
          <div className='RegisterContInput'>
            <div className='gradientBackground'>
              <label htmlFor="" className='centraColor bold'>APELLIDO 1</label>
            </div>
            <input type="text" name='primaryLastname' value={user.primaryLastname} onChange={onChangeHandler} required />
          </div>
          <div className='RegisterContInput'>
            <div className='gradientBackground'>
              <label htmlFor="" className='centraColor bold'>APELLIDO 2</label>
            </div>
            <input type="text" name='secondLastname' value={user.secondLastname} onChange={onChangeHandler} required />
          </div>
          <div className='RegisterContInput'>
            <div className='gradientBackground'>
              <label htmlFor="" className='centraColor bold'>E-MAIL</label>
            </div>
            <input type="email" name='email' value={user.email} onChange={onChangeHandler} required />
          </div>
          <div className='RegisterContInput'>
            <div className='gradientBackground'>
              <label htmlFor="" className='centraColor bold'>ESPECIALIDAD</label>
            </div>
            <select name="speciality" id="" value={user.speciality} onChange={onChangeHandler} required >
              <option value=""></option>
              <option value="MÉDICO GENERAL">MÉDICO GENERAL</option>
              <option value="GERIATRA">GERIATRA</option>
              <option value="INTERNISTA">INTERNISTA</option>
              <option value="ENDOCRINÓLOGO">ENDOCRINÓLOGO</option>
              <option value="NUTRICIONISTA">NUTRICIONISTA</option>
              <option value="CIRUJANO">CIRUJANO</option>
              <option value="ORTOPEDISTA">ORTOPEDISTA</option>
              <option value="ONCÓLOGO">ONCÓLOGO</option>
              <option value="OTRO">OTRO</option>
            </select>
          </div>
          <div className='RegisterContInput'>
            <div className='gradientBackground'>
              <label htmlFor="" className='centraColor bold'>PAÍS</label>
            </div>
            <select name="country" id="" value={user.country} onChange={onChangeHandler} required>
              <option value=""></option>
              <option value="MEXICO">MEXICO</option>
              <option value="COLOMBIA">COLOMBIA</option>
              <option value="PERÚ">PERÚ</option>
              <option value="ECUADOR">ECUADOR</option>
              <option value="ARGENTINA">ARGENTINA</option>
              <option value="CHILE">CHILE</option>
              <option value="PUERTO RICO">PUERTO RICO</option>
              <option value="BRASIL">BRASIL</option>
              <option value="GUATEMALA">GUATEMALA</option>
              <option value="COSTA RICA">COSTA RICA</option>
              <option value="HONDURAS">HONDURAS</option>
              <option value="NICARAGUA">NICARAGUA</option>
              <option value="EL SALVADOR">EL SALVADOR</option>
              <option value="PANAMÁ">PANAMÁ</option>
              <option value="BELICE">BELICE</option>
              <option value="REPÚBLICA DOMINICANA">REPÚBLICA DOMINICANA</option>
            </select>
          </div>
        </div>
        <div className='RegisterFinalSection'>
          <div className='RegisterFinalLeft'>
            <div className='RegisterLeftButtons '>
              <a href="https://www.latam.abbott/privacy-policy.html" target='_blank' rel="noopener noreferrer"><button className='whiteColor semiBold' type='button'>CONSULTE POLÍTICA DE PRIVACIDAD DE DATOS</button></a>
              <a href={API + "upload/terminos-y-Condiciones-CENTRA.pdf"} target='_blank' rel="noopener noreferrer"><button className='whiteColor semiBold' type='button'>CONSULTE TÉRMINOS Y CONDICIONES</button></a>
              <button className='whiteColor semiBold' type='button' onClick={() => history.push('/login')}>YA ESTOY REGISTRADO</button>
            </div>
            <div className='RegisterLeftChecks'>
              <div><input type="checkbox" required checked={checks.policy} onChange={(e) => setChecks({ ...checks, policy: e.target.checked })} /><label htmlFor="">Al registrarte aceptas la Política de Privacidad y el tratamiento de datos personales.</label></div>
              <div><input type="checkbox" required checked={checks.terms} onChange={(e) => setChecks({ ...checks, terms: e.target.checked })} /><label htmlFor="">Aceptas los términos y condiciones.</label></div>
              <div><input type="checkbox" checked={checks.info} onChange={(e) => setChecks({ ...checks, info: e.target.checked })} /><label htmlFor="">Estoy de acuerdo en recibir información de Abbott.</label></div>
            </div>
          </div>
          <div className='RegisterFinalRight'>
            <button type='submit' className='RegisterSendButton'>
              <h2 className='centraColor semiBold'>ENVIAR</h2>
              <div type='submit' className='RegisterFloatButton centraColor'><img src={sendArrow} alt="" /></div>
            </button>
          </div>
        </div>
      </form>
      <div className='SupButtonContainer' >
        <a href="https://wa.link/akse78" target="_blank" rel="noopener noreferrer">
          <button className='supButton centraColor semiBold gradientBackground'>SOPORTE</button>
        </a>
      </div>
    </div>
  )
}

export default withRouter(Register)