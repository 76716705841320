import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Menu from '../../components/menu'
import MobileMenu from '../../components/mobileMenu'
import './room.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { API, ws } from '../../config'
import io from 'socket.io-client'
import popUpQuestions from '../../media/PopUpQuestions.png'
import imgIframe from '../../media/bg/IMG-iframe.jpg'

const cookies = new Cookies();

let socket = null
let user = null

const Room = ({ history }) => {
  const [question, setQuestion] = useState('')
  const [spanishIframe, setSpanishIframe] = useState('')
  const [portugueseIframe, setPortugueseIframe] = useState('')
  const [language, setLanguage] = useState('')
  const [aux, setAux] = useState('')
  const [send, setSend] = useState(false)
  const [popUp, setPopUp] = useState('QuestionPopUpH')

  useEffect(() => {
    const getIframes = async () => {
      try {
        let res = await axios.get(API + 'settings/spanishIframe')
        setSpanishIframe(res.data.settings)
        setAux(res.data.settings)
        setLanguage(res.data.settings)
      } catch (error) {
        console.error(error);
        setSpanishIframe(' ')
        setAux(' ')
        setLanguage(' ')
      }
      try {
        let res = await axios.get(API + 'settings/portugueseIframe')
        setPortugueseIframe(res.data.settings)
      } catch (error) {
        console.error(error);
        setPortugueseIframe(' ')
      }
    }
    if (!cookies.get('user')) {
      history.push('/')
    } else {
      if (!user) {
        user = cookies.get('user')
        getIframes()
        document.title = 'CENTRA - Sala'
      }
      if (!socket || (socket && !socket.connected)) {
        socket = io.connect(ws, { transports: ['websocket'] })
        socket.emit('new-user', { name: `${user.name} ${user.primaryLastname} ${user.secondLastname}`, uID: user._id, page: user.email })
        setTimeout(() => {
          setSend(true)
        }, 10000);
      }
    }
  }, [history])

  useEffect(() => {
    if (language !== '') {
      if (socket) {
        socket.on('newConnection', () => {
          if (send) {
            socket.emit('new-user', { name: `${user.name} ${user.primaryLastname} ${user.secondLastname}`, uID: user._id, page: user.email })
          }
        })
        socket.on('userDuplicated', userID => {
          console.log("Duplicated --->", userID);
          if (userID === user._id) {
            history.push('/')
            socket.off()
            socket.disconnect()
          }
        })
        socket.on('streaming', stream => {
          console.log(stream);
          if (JSON.stringify(language) === JSON.stringify(spanishIframe)) {
            setLanguage('')
            setLanguage(stream)
          }
          if (JSON.stringify(spanishIframe) === JSON.stringify(aux)) {
            setAux(stream)
          }
          setSpanishIframe(stream)
          console.log(stream);
        })
        socket.on('streaming2', stream => {
          console.log(stream);
          if (JSON.stringify(language) === JSON.stringify(portugueseIframe)) {
            setLanguage('')
            setLanguage(stream)
          }
          if (JSON.stringify(portugueseIframe) === JSON.stringify(aux)) {
            setAux(stream)
          }
          setPortugueseIframe(stream)
        })
        socket.on('user-list', (users, topUsers) => {
          let counter = 0
          for (const key in users) {
            if (Object.hasOwnProperty.call(users, key)) {
              const element = users[key];
              if (element.uID === user._id) {
                counter++
              }
              if (counter >= 2) {
                history.push('/')
                socket.off()
                socket.disconnect()
                break
              }
            }
          }
        })
        socket.on('user-connected', u => {
          if (u.uID.toString() === user._id.toString()) {
            history.push('/')
            socket.off()
            socket.disconnect()
          }
        });
      }
    }
    return () => {
      if (socket) {
        socket.off()
      }
    }
  }, [aux, language, portugueseIframe, spanishIframe, send, socket, history])


  const sendQuestion = async (e) => {
    e.preventDefault()
    try {
      setQuestion('')
      const options = {
        data: { userId: user._id, message: question },
        method: 'POST',
        url: API + "messages",
        headers: { "Content-Type": "application/json" }
      }
      let res = await axios.request(options)
      setPopUp('QuestionPopUp')
      setTimeout(() => {
        setPopUp('QuestionPopUpH')
      }, 3500);
      if (socket) {
        res.data.userId = user
        socket.emit('chat', { message: res.data })
      }
    } catch (error) {

    }
  }

  const changeIframe = (e) => {
    setLanguage('')
    setLanguage(e.target.value)
    setAux(e.target.value)
  }

  return (
    <div className='RoomContainer'>
      <header className='RoomHeader'>
        <img src={Logo1} alt="FELANPE" />
        <Menu />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className='RoomContent'>
        <div className='RoomLeft'>
          <div className='RoomLeftInside'>
            <img src={LogoCentra} alt="Centra" className='RoomCentralLogo' />
            <MobileMenu />
          </div>
          < div className='iframe' dangerouslySetInnerHTML={{ __html: language }} key={language} />
          <select name="" id="" className='RoomLanguage' value={language} onChange={changeIframe}>
            <option value={aux}>SELECCIONE EL IDIOMA</option>
            <option value={spanishIframe}>ESPAÑOL</option>
            <option value={portugueseIframe}>PORTUGUÉS</option>
          </select>
        </div>
        <div className='RoomRight'>
          <div className='RoomRightInside'>
            <img src={LogoCentra} alt="Centra" className='RoomCentralLogo2' />
          </div>
          <form className='RoomQuestion' onSubmit={sendQuestion}>
            <textarea name="question" id="" placeholder='DEJA TU PREGUNTA' value={question} onChange={(e) => setQuestion(e.target.value)} required></textarea>
            <img className={popUp} src={popUpQuestions} alt="" />
            <button className='gradientBackground centraColor' type='submit'>ENVIAR <p>&#10148;</p></button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Room)