import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import "./slider.css"

let SliderComp = (props) => {
  const { select: [selected, setSelected] } = { select: useState(''), ...(props.state || {}) }

  var settings = {
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    swipeToSlide: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    dots: true,
    afterChange: (curr) => {
      setSelected([props.dayselected === "day1" ? props.day1 : props.dayselected === "day2" ? props.day2 : props.dayselected === "day3" ? props.day3 : props.day1][0][curr].ref)
    }
  };

  useEffect(() => {

    return () => { /* Unmount */ }
  }, [])

  return (
    <Slider className="slideComponent" {...settings}>
      {
        [props.dayselected === "day1" ? props.day1 : props.dayselected === "day2" ? props.day2 : props.dayselected === "day3" ? props.day3 : props.day1][0].map((ele, idx) => {
          return (
            <div className="speakerCont" key={idx} onClick={e => {setSelected(ele.ref)}}>
              <div className="imgSpk" style={{ backgroundImage: `url("${ele.img}")` }}></div>
              <div className="title">
                <h4>{ele.speaker}</h4>
                <p>{ele.title}</p>
              </div>
              <h4>{ele.conf}</h4>
            </div>
          )
        })
      }
    </Slider>
  )
}

export default SliderComp
