import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import './questions.css'
import { API } from '../../config'
import axios from 'axios'


const Questions = () => {
  const [messages, setMessages] = useState([])
  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(API + 'messages')
        setMessages(res.data)
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    }
    getMessages()
    const interval = setInterval(() => {
      getMessages()
    }, 15000);
    return () => {
      clearInterval(interval)
    }
  }, [])

  let reverseList = [...messages]
  reverseList = reverseList.reverse()

  return (
    <div>
      <div className='QuestionsContainer'>
        <h1>Preguntas</h1>
        <div className='QuestionsContent'>
          {reverseList ? reverseList.map((msg) => (
            !msg.deleted ?
              <div key={msg._id}>
                <p>{msg.userId ? `${msg.userId.name} ${msg.userId.primaryLastname} ${msg.userId.secondLastname}` : ''}</p>
                <p>{msg.message}</p>
              </div> : ''
          )) : ''}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Questions)
