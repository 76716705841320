import React, { useEffect } from 'react'
import leftArrow from '../../media/leftArrow.png'
import rigthArrow from '../../media/rigthArrow.png'
import './speakersBigSlider.css'
import divLine from './media/bigDivLine.png'
import speaker1 from './media/Ponente 1.png'
import speaker2 from './media/Ponente 2.png'
import speaker3 from './media/Ponente 3.png'
import speaker4 from './media/Ponente 4.png'
import speaker5 from './media/Ponente 5.png'
import speaker6 from './media/Ponente 6.png'
import speaker7 from './media/Ponente 7.png'
import speaker8 from './media/Ponente 8.png'
import speaker9 from './media/Ponente 9.png'
import speaker10 from './media/Ponente 10.png'
import speaker11 from './media/Ponente 11.png'

let currentSection = 1

const SpeakerBigSlider = ({ setState, section }) => {
  useEffect(() => {
    currentSection = section
  }, [section])

  return (
    <div className='BigSliderContainer'>
      {currentSection > 1 ?
        <a href={`#section${currentSection - 1}`} onClick={() => currentSection = currentSection - 1} className='BigSliderButtons yellowColor'>
          <img src={leftArrow} alt="" />
        </a>
        : <div style={{ width: '15px' }} />}
      <div className='BigSlider'>
        <ul className='BigSliderList centraColor bold'>
          <div className='BigSliderSection' id='section1' >
            <div className='BigSliderHeader'>
              <img src={speaker1} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Manuel Castillo Zegarra</h1>
                <h2>MD Internista-Nefrólogo</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médico-Cirujano y especialista en Nefrología de la Universidad Peruana Cayetano Heredia, es el actual Director Médico de la Unidad Básica de Hemodiálisis del Hospital Daniel Alcides Carrión, Director de TNT Renal (Total Nutrición Therapy)  desde el año 2018.</li>
                <li>Miembro titular de la Sociedad Peruana de Nefrología.</li>
                <li>Presidente de la Sociedad Peruana de Nefrología / Filial Callao (periodo 2021 a 2022).</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section2' >
            <div className='BigSliderHeader'>
              <img src={speaker2} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Bárbara Valenzuela</h1>
                <h2>MD Nutriólogo</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médico Cirujano Universidad de Chile.</li>
                <li>Magíster en ciencias biomédicas mención nutrición, Universidad de Chile.</li>
                <li>Ex jefa equipo unidad asistencia nutricional Hospital Dipreca y Hospital de Puerto Montt.</li>
                <li>Médico Nutriólogo Hospital de Puerto Montt.</li>
                <li>Miembro de la Asociación Chilena de Nutrición y Metabolismo.</li>
                <li>Miembro de la Sociedad Chilena de Cirugía Bariátrica y Metabólica.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section3' >
            <div className='BigSliderHeader'>
              <img src={speaker3} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Dolores Rodríguez</h1>
                <h2>MD y Magister en Nutrición</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Magister en Nutrición Universidad de Guayaquil.</li>
                <li>Diplomado en Servicio de Salud Universidad San Francisco Quito, Ecuador.</li>
                <li>Diplomado en Administración en Salud Universidad de la Sabana, Colombia.</li>
                <li>Doctor en Medicina y Cirugía Universidad Católica de Santiago de Guayaquil.</li>
                <li>Presidente de Federación Latinoamericana de Terapia Nutricional, Nutrición Clínica y Metabolismo FELANPE.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section4' >
            <div className='BigSliderHeader'>
              <img src={speaker4} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Olga Gómez Santamaría</h1>
                <h2>MD y Doctorado en Nutrición</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médico Cirujano con Maestría en Nutrición Clínica con Diplomado de Alta Especialidad en Nutrición Clínica HRLALM, estudiante de Doctorado en Ciencias de la Nutrición. Consulta privada y hospitalaria, con 18 años de experiencia, miembro de la asociación médica del Centro Médico ABC.</li>
                <li>Miembro activo y vicepresidente del “Colegio Mexicano de Nutrición Clínica y Terapia Nutricional” Certificación Internacional en nutrición clínica por ESPEN y coordinadora general de la Certificación en Nutrición Clínica en México ESPEN-México, así como profesora LLL.</li>
                <li>Profesora titular de Endonutrición en la Licenciatura de Medicina – Universidad Anáhuac.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section5' >
            <div className='BigSliderHeader'>
              <img src={speaker5} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Daniel Martínez</h1>
                <h2>MD Internista-Geriatra</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médico especialista en Medicina interna</li>
                <li>Médico especialista en Geriatría</li>
                <li>Formación de posgrado en Nutrición</li>
                <li>Jefe del servicio de geriatría del hospital Militar central de Bs As.</li>
                <li>Coordinador médico del Centro de apoyo nutricional. CAN</li>
                <li>Miembro de la academia de medicina del adulto mayor Fellow en Geriatría Universidad de Toronto. Canadá</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section6' >
            <div className='BigSliderHeader'>
              <img src={speaker6} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Consuelo Velázquez</h1>
                <h2>MD y Magister en Nutrición</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Es investigadora nivel I del sistema nacional de investigadores y catedrática de la Universidad Autónoma Metropolitana (UAM) unidad Xochimilco; Es médico cirujano por parte de la Universidad Autónoma del estado de México (UNAM) y cuenta con una maestría en ciencias, con especialidad en Nutrición por parte del centro de investigación en alimentación y desarrollo (CIAD).</li>
                <li>Es autora de varios capítulos en libros, y cuenta con extensivas participaciones en congresos nacionales e internacionales, así como en varias publicaciones en revistas especializadas con factor de impacto.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section7' >
            <div className='BigSliderHeader'>
              <img src={speaker7} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Gabriel Gómez</h1>
                <h2>MD Cirujano Cabeza y Cuello</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médico y Cirujano, Cirujano General, Cirujano de Cabeza y Cuello. </li>
                <li>Maestría en Epidemiología Clínica.</li>
                <li>Maestría en Educación.</li>
                <li>Director Médico Regional para América Latina de Abbott Nutrition.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section8' >
            <div className='BigSliderHeader'>
              <img src={speaker8} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Claudia Maza</h1>
                <h2>ND y Magister en Nutrición</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>International Master in Diet and Nutrition, Specializing in Childhood Nutrition. Universidad Europea del Atlántico.</li>
                <li>Maestría en Nutrición y Dietética con Especialización en Nutrición Clínica. Universidad Internacional Iberoamericana, México.</li>
                <li>Posgrado en Obesidad. Universidad Favaloro de Argentina.</li>
                <li>Licenciada en Nutrición. Universidad del Valle de Guatemala.</li>
                <li>Jefe de la Sección de Nutrición Clínica del Centro Médico Militar y miembro del equipo multidisciplinario de Soporte Nutricional del Centro Médico Militar.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section9' >
            <div className='BigSliderHeader'>
              <img src={speaker9} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Melina Castro</h1>
                <h2>MD Nutrióloga</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médica Doctorado Facultad de Medicina Universidad de São Paulo.</li>
                <li> Médica del Equipo Multidisciplinario de Terapia Nutricional del Hospital Israelita Albert Einstein y Presidente de BRASPEN – Brazilian Society Parenteral and Enteral Nutrition.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section10' >
            <div className='BigSliderHeader'>
              <img src={speaker10} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Anayanet Jáquez</h1>
                <h2>MD Nutriólogo</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Nutrióloga Clínica , investigación biomédica y clínica.</li>
                <li>Doctora en medicina Pontificia Universidad Católica Madre y Maestra (PUCMM).</li>
                <li>Principles and Practice of Clinical Research (PPCR)-Clinical Researcher Harvard Medical School.</li>
                <li>Maestría en Investigación Biomédica (PUCMM)</li>
                <li>Doctorado en Nutrición Clínica (Anahuac Norte) – en curso.</li>
                <li>Presidente de la Sociedad Dominicana de Nutrición Enteral y Parenteral (SODONEP)</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
          <div className='BigSliderSection' id='section11' >
            <div className='BigSliderHeader'>
              <img src={speaker11} alt="" className='BigSliderSpeaker' />
              <div>
                <h1>Fernando Pereira</h1>
                <h2>MD Cirujano-Especialista en Medicina Crítica y Cuidados intensivos</h2>
                <img className='divLine' src={divLine} alt="" />
              </div>
            </div>
            <div className='BigSliderContent'>
              <ul>
                <li>Médico y Cirujano Universidad Libre de Colombia</li>
                <li>Especialista en Cirugía General, Universidad de Cartagena.</li>
                <li>Especialista en Medicina Crítica y Cuidados intensivos de la Universidad Pontificia Bolivariana. Instructor TLLL ESPEN.</li>
                <li>Coordinador Soporte Nutricional, Clínica las Américas, Medellín.</li>
              </ul>
            </div>
            <div className='BigSliderCloseButtonContainer'>
              <button className='BigSliderCloseButton centraColor' onClick={() => setState(false)}>X</button>
            </div>
          </div>
        </ul>
      </div>
      {currentSection < 11 ?
        <a href={`#section${parseInt(currentSection, 10) + 1}`} onClick={() => currentSection = parseInt((currentSection) + 1, 10)} className='BigSliderButtons yellowColor'>
          <img src={rigthArrow} alt="" />
        </a>
        : <div style={{ width: '15px' }} />}
    </div>
  )
}
export default SpeakerBigSlider