import React, { useState } from 'react'
import './mobileMenu.css'
import popUp1 from '../media/PopUpMenu.png'
import popUp2 from '../media/PopUpMenu2.png'
import MenuIcon from '../media/menuMobileIcon.png'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const redirect = (place) => {
  window.location = window.location.origin + '/' + place
}

const MobileMenu = () => {
  const [state, setState] = useState('MenuMobilePopUp1')
  const [state2, setState2] = useState('MenuMobilePopUp2')
  const [deployed, setDeployed] = useState('MobileMenuContent')

  const certificate = () => {
    const finishDate = (new Date('Jul 31, 2021 00:00:00').getTime())
    const currentTime = new Date().getTime();
    const distanceToDate = finishDate - currentTime;
    if (Math.floor((distanceToDate % (1000 * 60)) / 1000) <= 0) {
      redirect('certificate')
    } else {
      showPopUp2()
    }
  }

  const memories = () => {
    const finishDate = new Date('Jul 30, 2021 00:00:00').getTime()
    const currentTime = new Date().getTime();
    const distanceToDate = finishDate - currentTime;
    if (Math.floor((distanceToDate % (1000 * 60)) / 1000) <= 0) {
      redirect('memorias')
    } else {
      showPopUp1()
    }
  }

  const showPopUp1 = () => {
    setState('MenuMobilePopUp1v')
    setTimeout(() => {
      setState('MenuMobilePopUp1')
    }, 4000);
  }

  const showPopUp2 = () => {
    setState2('MenuMobilePopUp2v')
    setTimeout(() => {
      setState2('MenuMobilePopUp2')
    }, 4000);
  }

  const logOut = () => {
    cookies.remove('user', { path: '/' })
    redirect('login')
  }

  return (
    <div className='MobileMenuContainer'>
      <div className='MobileMenuDeploy' onClick={() => setDeployed(deployed === 'MobileMenuDeployed' ? 'MobileMenuContent' : 'MobileMenuDeployed')}>
        <img className='MobileMenuIcon' src={MenuIcon} alt="Menu" />
      </div>
      <div className={deployed}>
        <div className='MobileMenuItem whiteColor MobileMenuBorder' onClick={() => redirect('room')}>
          <h4>CONFERENCIAS</h4>
        </div>
        <div className='MobileMenuItem whiteColor MobileMenuBorder' onClick={() => redirect('agenda')}>
          <h4>AGENDA</h4>
        </div>
        <div className='MobileMenuItem whiteColor MobileMenuBorder' onClick={memories}>
          <h4>MEMORIAS</h4>
          <img src={popUp2} alt="" className={state} />
        </div>
        <div className='MobileMenuItem whiteColor MobileMenuBorder' onClick={() => redirect('speakers')}>
          <h4>PONENTES</h4>
        </div>
        <div className='MobileMenuItem whiteColor MobileMenuBorder' onClick={certificate}>
          <h4>CERTIFICADO DE ASISTENCIA</h4>
          <img src={popUp1} alt="" className={state2} />
        </div>
        <div className='MobileMenuItem whiteColor' onClick={logOut}>
          <h4>CERRAR SESIÓN</h4>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu