import React, { useEffect, useState, useCallback } from "react"
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import './admin.css'
import { API, ws } from '../../config'
import io from 'socket.io-client'
import Cookies from 'universal-cookie'
import MemAdmin from './memorias/memoriasAdmin'


const spanishIframe = async (iframe) => {
  const options = {
    data: { settings: iframe },
    method: 'PATCH',
    url: API + "settings/spanishIframe",
    headers: { "Content-Type": "application/json" }
  }
  try {
    await axios.request(options)
  } catch (error) {
    console.error(error);
  }
}

const portugueseIframe = async (iframe) => {
  const options = {
    data: { settings: iframe },
    method: 'PATCH',
    url: API + "settings/portugueseIframe",
    headers: { "Content-Type": "application/json" }
  }
  try {
    await axios.request(options)
  } catch (error) {
    console.error(error);
  }
}

const scriptToIframe = (iframeJs) => {
  if (iframeJs.length > 0 && iframeJs[1] === 's') {
    try {
      iframeJs = iframeJs.split('(');
      iframeJs.pop();
      iframeJs = iframeJs[iframeJs.length - 1];
      iframeJs = iframeJs.split(',');

      var regexS = /'/gi;

      var p = iframeJs[0].replace(regexS, '');
      var c = iframeJs[1].replace(regexS, '');
      var q = iframeJs[2].split('+')[0].replace(regexS, '');

      q = q + encodeURIComponent(window.location.hostname)

      c = c.replace(/ /gi, '');

      //frameTest.appendChild(newIframe);
      const iframe = `<iframe className='iframe' title='spanish' id='${p + c}' frameBorder='0' scrolling='no' allow='autoplay; fullscreen' src='https://boxcast.tv/view-embed/${c}?${q}' />`
      return iframe
    } catch (error) {
      console.error(error);
    }
  }
}

const cookies = new Cookies();
let socket = null
let user = null

const Admin = ({ history }) => {
  const [messages, setMessages] = useState([])
  const [register, setRegister] = useState([])
  const [connected, setConnected] = useState([])
  const [spanish, setSpanish] = useState('')
  const [spanish1, setSpanish1] = useState('')
  const [spanish2, setSpanish2] = useState('')
  const [portuguese, setPortuguese] = useState('')
  const [portuguese1, setPortuguese1] = useState('')
  const [portuguese2, setPortuguese2] = useState('')
  const [recording, setRecording] = useState(false)

  const getRegistered = useCallback(
    async () => {
      try {
        const res = await axios.get(API + 'register')
        setRegister(res.data)
      } catch (error) {
        console.error(error);
      }
    },
    [],
  )

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(API + 'messages')
        setMessages(res.data)
      } catch (error) {
        console.error(error);
      }
    }
    if (!cookies.get('user') || cookies.get('user').email !== process.env.REACT_APP_USER_ADMIN) {
      history.push('/')
    } else if (!user) {
      user = cookies.get('user')
      getMessages()
      getRegistered()
    }
    if (user) {
      if (!socket || (socket && !socket.connected)) {
        socket = io.connect(ws, { transports: ['websocket'] })
      }
      socket.on('newConnection', () => {
        socket.emit('new-user', 'adminload')
      })
      socket.on('user-list', (users, topUsers) => {
        setConnected([])
        setConnected(Object.values(users))
      });
      socket.on('recording', recording => {
        setRecording(recording)
      })
      socket.on('chat', newMsg => {
        let auxMsgs = [...messages]
        auxMsgs.push(newMsg.message)
        setMessages(auxMsgs)
      })
      socket.on('starRecords', () => {
        setRecording(true)
      })
      socket.on('stopRecords', () => {
        setRecording(false)
      })
    }
    return () => {
      if (socket) {
        socket.off()
      }
    }
  }, [getRegistered, history, messages])


  const deleteQuestion = async (msg) => {
    const options = {
      data: { ...msg, deleted: !msg.deleted },
      method: 'PATCH',
      url: API + 'messages/' + msg._id,
      headers: { "Content-Type": "application/json" }
    }
    try {
      await axios.request(options)
      setMessages(messages.filter((msgAux) => msgAux._id !== msg._id))
    } catch (error) {
      console.error(error);
    }
  }

  const probeSpanish = () => {
    if (spanish1.trim() !== '') {
      setSpanish(spanish1)
    } else if (spanish2.trim() !== '') {
      setSpanish(scriptToIframe(spanish2))
    }
  }

  const probePortuguese = () => {
    if (portuguese1.trim() !== '') {
      setPortuguese(portuguese1)
    } else if (portuguese2.trim() !== '') {
      setPortuguese(scriptToIframe(portuguese2))
    }
  }

  const changeSpanish = () => {
    if (spanish1.trim() !== '') {
      spanishIframe(spanish1)
      setSpanish1('')
      socket.emit('streaming', spanish1)
    } else if (spanish2.trim() !== '') {
      spanishIframe(scriptToIframe(spanish2))
      setSpanish2('')
      socket.emit('streaming', scriptToIframe(spanish2))
    }
  }

  const changePortuguese = () => {
    if (portuguese1.trim() !== '') {
      portugueseIframe(portuguese1)
      setPortuguese1('')
      socket.emit('streaming2', portuguese1)
    } else if (portuguese2.trim() !== '') {
      portugueseIframe(scriptToIframe(portuguese2))
      setPortuguese2('')
      socket.emit('streaming2', scriptToIframe(portuguese2))
    }
  }

  const startRecord = () => {
    if (socket) {
      socket.emit('starRecords')
    }
  }

  const stopRecord = () => {
    if (socket) {
      socket.emit('stopRecords')
    }
  }

  let reverseList = [...messages]
  reverseList = reverseList.reverse()

  return (
    <div className='AdminContainer'>
      <div className='AdminQuestions'>
        <h1>Preguntas</h1>
        <div className='AdminQuestionsContent'>
          {reverseList ? reverseList.map((msg) => (
            !msg.deleted ?
              <div key={msg._id}>
                <label>{new Date(msg.date).toLocaleString('es-CO')}</label>
                <p>{msg.userId ? `${msg.userId.name} ${msg.userId.primaryLastname} ${msg.userId.secondLastname}` : ''}</p>
                <p>{msg.message}</p>
                <button onClick={() => deleteQuestion(msg)}>X</button>
              </div> : ''
          )) : ''}
        </div>
      </div>
      <div className='AdminRecords'>
        <h1>Guardar Conexiones</h1>
        {
          recording ?
            <div>
              <h2>Guardando registro de conectados</h2>
              <div className='recording'></div>
              <button onClick={stopRecord}>PARAR</button>
            </div>
            :
            <div>
              <h2>Aun no empiezas a guardar conexiones</h2>
              <div className='noRecording'></div>
              <button onClick={startRecord}>COMENZAR</button>
            </div>
        }
      </div>
      <div className='AdminIframe'>
        <h1>Iframes</h1>
        <div className='AdminIframeColumns'>
          <div className='AdminIframeLeft'>
            <h2>Cambiar Iframe Español</h2>
            <div>
              <h4>Ingrese Iframe</h4>
              <textarea name="" id="" cols="30" rows="3" value={spanish1} onChange={(e) => setSpanish1(e.target.value)}></textarea>
              <h4>Ingrese Script</h4>
              <textarea name="" id="" cols="30" rows="3" value={spanish2} onChange={(e) => setSpanish2(e.target.value)}></textarea>
            </div>
            <div className='iframe1' dangerouslySetInnerHTML={{ __html: spanish }} />
            <div>
              <button onClick={changeSpanish}>Agregar</button>
              <button onClick={probeSpanish}>Probar</button>
            </div>
          </div>
          <div>
            <div className='AdminIframeRight'>
              <h2>Cambiar Iframe Portugues</h2>
              <h4>Ingrese Iframe</h4>
              <textarea name="" id="" cols="30" rows="3" value={portuguese1} onChange={(e) => setPortuguese1(e.target.value)}></textarea>
              <h4>Ingrese Script</h4>
              <textarea name="" id="" cols="30" rows="3" value={portuguese2} onChange={(e) => setPortuguese2(e.target.value)}></textarea>
              <div className='iframe2' dangerouslySetInnerHTML={{ __html: portuguese }} />
              <div>
                <button onClick={changePortuguese}>Agregar</button>
                <button onClick={probePortuguese}>Probar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MemAdmin />
      <div className='AdminConnected'>
        <h1>Conectados : {connected ? connected.length : ''}</h1>
        <div className='AdminRegisterContent'>
          {connected ? connected.map((usr) => (
            <div key={usr._id + usr.name}>
              <p>{usr.name}</p>
              <p>{usr.page}</p>
            </div>
          )) : ''}
        </div>
      </div>
      <div className='AdminRegister'>
        <h1>Registrados : {register ? register.length : ''}</h1>
        <button onClick={getRegistered}>Actualizar</button>
        <div className='AdminRegisterContent'>
          {register ? register.map((usr) => (
            <div key={usr._id}>
              <p>{`${usr.name} ${usr.primaryLastname} ${usr.secondLastname}`}</p>
              <p>{usr.email}</p>
            </div>
          )) : ''}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Admin)