//Speakers
import ConsueloV from '../speakers/media/Ponente 6.png'
import ManuelC from '../speakers/media/Ponente 1.png'
import BarbaraV from '../speakers/media/Ponente 2.png'
import DoloresR from '../speakers/media/Ponente 3.png'
import OlgaG from '../speakers/media/Ponente 4.png'
import DanielM from '../speakers/media/Ponente 5.png'
import MelinaC from '../speakers/media/Ponente 9.png'
import GabrielG from '../speakers/media/Ponente 7.png'
import ClaudiaM from '../speakers/media/Ponente 8.png'
import AnaY from '../speakers/media/Ponente 10.png'
import FernandoP from '../speakers/media/Ponente 11.png'


let day27 = [
  {
    ref: "D1-C1",
    speaker: "Consuelo Velasquez",
    title: "MD y Magister en Nutrición",
    conf: "Sarcopenia y fragilidad: Dos comorbilidades desafiantes en enfermedad Hepática Crónica",
    img: ConsueloV
  },
  {
    ref: "D1-C2",
    speaker: "Manuel Castillo Zegarra",
    title: "MD Internista-Nefrólogo",
    conf: "Nutrición, fragilidad y Enfermedad Renal Crónica",
    img: ManuelC
  },
  {
    ref: "D1-C3",
    speaker: "Bárbara Valenzuela",
    title: "MD Nutriólogo",
    conf: "Metodología de la búsqueda de estudios en nutrición: desde el tipo de estudio hasta análisis de datos",
    img: BarbaraV
  },
  {
    ref: "D1-C4",
    speaker: "Dolores Rodríguez",
    title: "MD y Magister en Nutrición",
    conf: "Nutrición de Precisión",
    img: DoloresR
  },
]

let day28 = [
  {
    ref: "D2-C1",
    speaker: "Olga Gómez Santamaría",
    title: "MD y Doctorado en Nutrición",
    conf: "Comorbilidades en Salud Metabólica",
    img: OlgaG
  },
  {
    ref: "D2-C2",
    speaker: "Daniel Martínez",
    title: "MD Internista-Geriatra",
    conf: "Investigación en Pandemia: por qué, qué y cómo?",
    img: DanielM
  },
  {
    ref: "D2-C3",
    speaker: "Melina Castro",
    title: "MD Nutriólogo",
    conf: "Principios del soporte nutricional en la práctica clínica",
    img: MelinaC
  },
  {
    ref: "D2-C4",
    speaker: "Gabriel Gómez",
    title: "MD Cirujano Cabeza y Cuello",
    conf: "Estudio SALUD  Nueva evidencia e impacto de la intervención nutricional",
    img: GabrielG
  },
]

let day29 = [
  {
    ref: "D3-C1",
    speaker: "Claudia Maza",
    title: "ND y Magister en Nutrición",
    conf: "Retos nutricionales en el manejo de complicaciones gastrointestinales inducidas",
    img: ClaudiaM
  },
  {
    ref: "D3-C2",
    speaker: "Melina Castro",
    title: "MD Nutriólogo",
    conf: "Ritmos circadianos e implicaciones para la nutrición y la medicina",
    img: MelinaC
  },
  {
    ref: "D3-C3",
    speaker: "AnaYanet Jáquez",
    title: "MD Nutriólogo",
    conf: "Nutrición y salud Osea",
    img: AnaY
  },
  {
    ref: "D3-C4",
    speaker: "Fernando Pereira",
    title: "MD Cirujano-Especialista en Medicina Crítica y Cuidados intensivos",
    conf: "Alimentación enteral: Alimentando al paciente críticamente inestable",
    img: FernandoP
  },
]

export {
  day27,
  day28,
  day29,
}