import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import './login.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import Separator from '../../media/separator.png'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { API } from '../../config'
import PopUp from '../../media/PopUpLogin.png'

const cookies = new Cookies();


const Login = ({ history }) => {
  const [email, setEmail] = useState('')
  const [visible, setVisible] = useState('LoginPopUp2')

  const onSubmit = async (e) => {
    e.preventDefault()
    const options = {
      data: { user: email.trim().toLowerCase(), password: '' },
      method: 'POST',
      url: API + "auth/login",
      headers: { "Content-Type": "application/json" }
    }
    try {
      const res = await axios.request(options)
      cookies.set('user', res.data.data.user, { path: '/' })
      if (res.data.data.user.email === process.env.REACT_APP_USER_ADMIN) {
        history.push('/admin')
      } else {
        history.push('/room')
      }
    } catch (error) {
      setVisible('LoginPopUp')
      setTimeout(() => {
        setVisible('LoginPopUp2')
      }, 5000);
    }
  }

  document.title = 'CENTRA - Ingreso'

  return (
    <div className='LoginContainer'>
      <header className='LoginHeader'>
        <img src={Logo1} alt="FELANPE" />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className='LoginContent'>
        <div className='LoginLeft'>
          <h1 className='LoginTitle whiteColor'>BIENVENIDO A</h1>
          <img src={LogoCentra} alt="Centra" className='LoginCentralLogo' />
        </div>
        <img src={Separator} alt="" className='LoginSeparator' />
        <div className='LoginRight'>
          <form className='LoginForm gradientBackground' onSubmit={onSubmit}>
            <h3 className='centraColor'>POR FAVOR DIGITE SU CORREO ELECTRÓNICO REGISTRADO</h3>
            <img src={PopUp} alt="" className={visible} />
            <input type="email" name='email' className='centraColor' value={email} onChange={(e) => setEmail(e.target.value)} required />
            <button type='submit' className='whiteColor semiBold centraBackground'>ENTRAR</button>
            <a href="registeruser" style={{ color: '#002e6d', textDecoration: 'none', fontWeight: '900', fontSize: '20px' }}>REGÍSTRESE</a>
          </form>
          <a href="https://wa.link/akse78" className="suport" target="_blank" rel="noopener noreferrer">
            <button type='button' className='LoginSupButton whiteColor'>SOPORTE</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Login)