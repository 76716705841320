import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Agenda from './pages/agenda/agenda';
import Certificate from './pages/certificate/certificate';
import Landing from './pages/landing/landing'
import Login from './pages/login/login';
import Register from './pages/register/register';
import Room from './pages/room/room';
/* import Room2 from './pages/room/room2'; */
import Speakers from './pages/speakers/speakers';
import Admin from './pages/admin/admin'
import Memories from './pages/memories/memories'
import Questions from './pages/questions/questions'

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={Landing} />
        <Route path='/registeruser' exact component={Register} />
        <Route path='/login' exact component={Login} />
        <Route path='/agenda' exact component={Agenda} />
        <Route path='/certificate' exact component={Certificate} />
        <Route path='/speakers' exact component={Speakers} />
        <Route path='/room' exact component={Room} />
        {/* <Route path='/room2' exact component={Room2} /> */}
        <Route path='/admin' exact component={Admin} />
        <Route path='/memorias' exact component={Memories} />
        <Route path='/questionscentraabbott' exact component={Questions} />
        <Route component={Landing} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
