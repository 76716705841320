import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Menu from '../../components/menu'
import MobileMenu from '../../components/mobileMenu'
import './agenda.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import DesktopAgenda from './desktopAgenda'
import MobileAgenda from './agendaMobile'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const Agenda = ({ history }) => {
  useEffect(() => {
    if (!cookies.get('user')) {
      history.push('/')
    }
    document.title = 'CENTRA - Agenda'

  }, [history])

  return (
    <div className='AgendaContainer'>
      <header className='AgendaHeader'>
        <img src={Logo1} alt="FELANPE" />
        <Menu />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className='AgendaContent'>
        <div className='AgendaLeft'>
          <div className='AngendaLeftInside'>
            <h1 className='AgendaTitle whiteColor'>NO SE PIERDA NINGÚN MOMENTO DE</h1>
            <img src={LogoCentra} alt="Centra" className='AgendaCentralLogo' />
          </div>
          <MobileMenu />
        </div>
        <div className='AgendaRight'>
          <DesktopAgenda />
          <MobileAgenda />
        </div>
      </div>
    </div>
  )
}

export default withRouter(Agenda)