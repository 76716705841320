import React from 'react'
import { withRouter } from 'react-router-dom'
import './landing.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import Counter from './counter'

const Landing = ({ history }) => {
  document.title = 'CENTRA'

  return (
    <div className='LandingContainer'>
      <header className='LandingHeader'>
        <img src={Logo1} alt="FELANPE" />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className='LandingContent'>
        <h1 className='LandingTitle'>BIENVENIDO A</h1>
        <img src={LogoCentra} alt="Centra" className='LandingCentralLogo' />
        <Counter />
        <div className='LandingButtonContainer' >
          <div className="actions">
            <button className='centraColor semiBold gradientBackground' onClick={() => history.push('/registeruser')}>REGÍSTRESE</button>
            <button className='centraColor semiBold gradientBackground' onClick={() => history.push('/login')}>INGRESE</button>
          </div>
          <p className='yellowColor'>*Material dirigido exclusivamente a profesionales de la salud.</p>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Landing)