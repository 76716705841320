import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import './certificate.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import Separator from '../../media/separator.png'
import Menu from '../../components/menu'
import MobileMenu from '../../components/mobileMenu'
import { useState } from 'react'
import { API } from '../../config'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { jsPDF } from "jspdf"
import certificado from '../../media/certificado.png'

const cookies = new Cookies();
let user = null

const Certificate = ({ history }) => {
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(true)
  const canvasRef = useRef(null)

  useEffect(() => {
    const calculateAttendance = async () => {
      let res = {}
      try {
        res = await axios.get(`${API}connected`)
      } catch (error) {
        console.error(error);
        setLoading(false)
        return
      }
      let counter = 0
      const auxUser = JSON.stringify({ name: `${user.name} ${user.primaryLastname} ${user.secondLastname}`, uID: user._id, page: user.email })

      for (const key in res.data) {
        if (Object.hasOwnProperty.call(res.data, key)) {
          const element = res.data[key];
          for (const usr of element.user) {
            if (JSON.stringify(usr) === auxUser) {
              counter += 1
            }
          }
        }
      }
      if (((counter * 100) / res.data.length) >= 50) {
        console.log(((counter * 100) / res.data.length));
        setValid(true)
      } else {
        console.log("no valido");
      }
      setLoading(false)
    }
    if (!cookies.get('user')) {
      history.push('/')
    } else {
      user = cookies.get('user')
      calculateAttendance()
    }
  }, [history])

  let createPDF = () => {
    setLoading(true)
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    console.log(canvas);
    let imageObj1 = new Image();
    imageObj1.src = certificado
    imageObj1.onload = async () => {
      if (`${user.name} ${user.primaryLastname} ${user.secondLastname}`.length > 24) {
        ctx.font = "65px BrandonGrotesque";
      } else {
        ctx.font = "90px BrandonGrotesque";
      }
      ctx.fillText(`Test`, canvas.width / 1.65, canvas.height / 4.2);
    }
    setTimeout(() => {
      ctx.drawImage(imageObj1, 0, 0, 1920, 1426);
      if (`${user.name} ${user.primaryLastname} ${user.secondLastname}`.length > 24) {
        ctx.font = "65px BrandonGrotesque";
      } else {
        ctx.font = "90px BrandonGrotesque";
      }
      ctx.fillStyle = '#002b81'
      ctx.textAlign = "center";
      ctx.fillText(`${user.name} ${user.primaryLastname} ${user.secondLastname}`.toUpperCase(), canvas.width / 1.65, canvas.height / 4.2);
    }, 1000);
    setTimeout(() => {
      let img = canvas.toDataURL("image/jpeg")

      const docPDF = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [15, (15 / 1.35)]
      });

      docPDF.addImage(img, 'JPEG', 0, 0, 15, (15 / 1.35))
      docPDF.save(`certificado-${user.name} ${user.primaryLastname} ${user.secondLastname}.pdf`);
      setLoading(false)
    }, 4000);
  }

  document.title = 'CENTRA - Certificado'

  return (
    <div className='CertificateContainer certifiedCont'>
      {/* {
        popup &&
        <div className="modalCont" onClick={e => {closeAnyWay(e)}}>
          <div className="close">X</div>
        </div>
      } */}
      <header className='CertificateHeader'>
        <img src={Logo1} alt="FELANPE" />
        <Menu />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className='CertificateContent'>
        <div className='CertificateLeft'>
          <div>
            <h1 className='CertificateTitle whiteColor'>BIENVENIDO A</h1>
            <img src={LogoCentra} alt="Centra" className='CertificateCentralLogo' />
          </div>
          <MobileMenu />
        </div>
        <img src={Separator} alt="" className='CertificateSeparator' />
        <div className='CertificateRight'>
          <div>
            {loading ?
              <div className='CertificateForm gradientBackground'>
                <div className='preloader'></div>
              </div>
              : valid ?
                <div className='CertificateForm gradientBackground'>
                  <h3 className='centraColor bold'>DESCARGUE AQUÍ EL CERTIFICADO DE ASISTENCIA:</h3>
                  {/* {
                    /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
                    ? <a href="https://wa.link/akse78" className="" target="_blank" rel="noopener noreferrer">
                        <button type='button' className='whiteColor semiBold centraBackground'>SOPORTE</button>
                      </a>
                    : <button type='submit' className='whiteColor semiBold centraBackground' onClick={createPDF}>DESCARGAR</button>
                  } */}
                  <button type='submit' className='whiteColor semiBold centraBackground' onClick={createPDF}>DESCARGAR</button>
                </div>
                :
                <div className='CertificateForm gradientBackground'>
                  <h3 className='centraColor thinBold'>SU USUARIO <b>NO COMPLETÓ EL 70% DE ASISTENCIA AL CURSO,</b> SI TIENE ALGUNA INQUIETUD AL RESPECTO POR FAVOR COMUNICARSE CON SU EJECUTIVO DE ABBOTT</h3>
                </div>
            }
          </div>
        </div>
      </div>
      {/* width={6251} height={4644} */}
      <canvas className="canvas" ref={canvasRef} width={1920} height={1426} style={{ fontFamily: 'BrandonGrotesque !important' }} />
    </div>
  )
}

export default withRouter(Certificate)