import React, { useState } from "react"

const DesktopAgenda = () => {
  const [state1, setState1] = useState(false)
  const [state2, setState2] = useState(false)
  const [state3, setState3] = useState(false)

  return (

    <div className='AgendaDesktop'>
      <ul className='AgendaList centraColor bold'>
        {/* ------------------------27 de JULIO ----------------------------------- */}
        <div className='AgendaDesktopSection' id=''>
          <div className='AgendaDesktopTitle'>
            <h3 className='centraBackground whiteColor'>27 JULIO</h3>
            {!state1 ?
              <div className='gradientBackground pointer' onClick={() => setState1(true)}>AGENDAR EN MI CALENDARIO</div>
              :
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <a target="_blank" rel="noopener noreferrer" href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210727T215000Z%2F20210728T005100Z&details=CENTRA%20Abbott%20D%C3%ADa%201%0Ahttps%3A%2F%2Fcentraabbott.com%2F&location=https%3A%2F%2Fcentraabbott.com%2F&text=Centra%20Abbott%20Dia%201" title="Save Event in my Calendar" >
                  <div className='AgendaInsideCalendar gradientBackground pointer'>Google</div>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://outlook.live.com/calendar/0/deeplink/compose?body=CENTRA%20Abbott%20D%C3%ADa%201%0Ahttps%3A%2F%2Fcentraabbott.com%2F&enddt=2021-07-28T00%3A51%3A00%2B00%3A00&location=https%3A%2F%2Fcentraabbott.com%2F&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2021-07-27T21%3A50%3A00%2B00%3A00&subject=Centra%20Abbott%20Dia%201" title="Save Event in my Calendar" >
                  <div className='AgendaInsideCalendar gradientBackground pointer'>Outlook</div>
                </a>
              </div>
            }
          </div>
          <div>
            <li className='AgendaItem'>
              <p>4:50 pm</p>
              <p>INGRESO A LA PLATAFORMA</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:00 pm</p>
              <p>BIENVENIDA</p>
              <p className='AgendaSpeaker'>Refaat Hegazi, MD Dir. Médica Global</p>
              <p className='AgendaSpeaker'>Gabriel Gómez, MD Dir. Médico Latinoamérica</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:05 pm</p>
              <p>APERTURA</p>
              <p className='AgendaSpeaker'>Oscar Hincapie, MD supervisor de asuntos médicos Latinoamérica</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:10 pm</p>
              <p className='skyColor'>CONFERENCIA #1</p>
              <p className='AgendaTopic'>Sarcopenia y fragilidad: Dos comorbilidades desafiantes en enfermedad Hepática Crónica</p>
              <p className='AgendaSpeaker'>Consuelo Velasquez, MD y Magister en Nutrición</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:35 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:50 pm</p>
              <p className='skyColor'>CONFERENCIA #2</p>
              <p className='AgendaTopic'>Nutrición, fragilidad y Enfermedad Renal Crónica</p>
              <p className='AgendaSpeaker'>Manuel Castillo Zegarra, MD Internista-Nefrólogo </p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:15 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:30 pm</p>
              <p className='skyColor'>CONFERENCIA #3</p>
              <p className='AgendaTopic'>Metodología de la búsqueda de estudios en nutrición: desde el tipo de estudio hasta análisis de datos</p>
              <p className='AgendaSpeaker'>Bárbara Valenzuela, MD Nutriólogo</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:55 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>7:10 pm</p>
              <p className='skyColor'>CONFERENCIA #4</p>
              <p className='AgendaTopic'>Nutrición de Precisión</p>
              <p className='AgendaSpeaker'>Dolores Rodríguez, MD y Magister en Nutrición</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>7:35 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div className='AgendaMobileEnd'>
            <p className='whiteColor'>7:50 pm</p>
            <p className='whiteColor'>CIERRE Y DESPEDIDA</p>
            <p className='AgendaSpeaker whiteColor'>Gabriel Gómez, MD Dir. Médico Latinoamérica</p>
          </div>
        </div>
        <div className='AgendaDesktopSection' id=''>
          {/* ------------------------28 de JULIO ----------------------------------- */}
          <div className='AgendaDesktopTitle'>
            <h3 className='centraBackground whiteColor'>28 JULIO</h3>
            {!state2 ?
              <div className='gradientBackground pointer' onClick={() => setState2(true)}>AGENDAR EN MI CALENDARIO</div>
              :
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <a target="_blank" rel="noopener noreferrer" href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210728T215000Z%2F20210729T004800Z&details=CENTRA%20Abbott%20D%C3%ADa%202%0Ahttps%3A%2F%2Fcentraabbott.com%2F&location=https%3A%2F%2Fcentraabbott.com%2F&text=Centra%20Abbott%20Dia%202" title="Save Event in my Calendar" >
                  <div className='AgendaInsideCalendar gradientBackground pointer'>Google</div>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://outlook.live.com/calendar/0/deeplink/compose?body=CENTRA%20Abbott%20D%C3%ADa%202%0Ahttps%3A%2F%2Fcentraabbott.com%2F&enddt=2021-07-29T00%3A48%3A00%2B00%3A00&location=https%3A%2F%2Fcentraabbott.com%2F&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2021-07-28T21%3A50%3A00%2B00%3A00&subject=Centra%20Abbott%20Dia%202" title="Save Event in my Calendar" >
                  <div className='AgendaInsideCalendar gradientBackground pointer'>Outlook</div>
                </a>
              </div>
            }
          </div>
          <div>
            <li className='AgendaItem'>
              <p>4:50 pm</p>
              <p>INGRESO A LA PLATAFORMA</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:00 pm</p>
              <p>BIENVENIDA SEGUNDO DÍA</p>
              <p className='AgendaSpeaker'>Gabriel Gómez, MD Dir. Médico Latinoamérica</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:05 pm</p>
              <p className='skyColor'>CONFERENCIA #1</p>
              <p className='AgendaTopic'>Comorbilidades en Salud Metabólica</p>
              <p className='AgendaSpeaker'>Olga Gómez Santamaría, MD y Doctorado en Nutrición</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:30 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:45 pm</p>
              <p className='skyColor'>CONFERENCIA #2</p>
              <p className='AgendaTopic'>Investigación en Pandemia: por qué, qué y cómo?</p>
              <p className='AgendaSpeaker'>Daniel Martínez, MD Internista-Geriatra</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:10 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:25 pm</p>
              <p className='skyColor'>CONFERENCIA #3</p>
              <p className='AgendaTopic'>Principios del soporte nutricional en la práctica clínica</p>
              <p className='AgendaSpeaker'>Melina Castro, MD Nutriólogo</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:50 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>7:05 pm</p>
              <p className='skyColor'>CONFERENCIA #4</p>
              <p className='AgendaTopic'>Estudio SALUD  Nueva evidencia e impacto de la intervención nutricional</p>
              <p className='AgendaSpeaker'>Gabriel Gómez, MD Cirujano Cabeza y Cuello</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>7:30 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div className='AgendaMobileEnd'>
            <p className='whiteColor'>7:45 pm</p>
            <p className='whiteColor'>CIERRE Y DESPEDIDA</p>
            <p className='AgendaSpeaker whiteColor'>Oscar Hincapie, MD supervisor de asuntos médicos Latinoamérica</p>
          </div>
        </div>
        <div className='AgendaDesktopSection' id=''>
          {/* ------------------------29 de JULIO ----------------------------------- */}
          <div className='AgendaDesktopTitle'>
            <h3 className='centraBackground whiteColor'>29 JULIO</h3>
            {!state3 ?
              <div className='gradientBackground pointer' onClick={() => setState3(true)}>AGENDAR EN MI CALENDARIO</div>
              :
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <a target="_blank" rel="noopener noreferrer" href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20210729T215000Z%2F20210730T004800Z&details=CENTRA%20Abbott%20D%C3%ADa%203%0Ahttps%3A%2F%2Fcentraabbott.com%2F&location=https%3A%2F%2Fcentraabbott.com%2F&text=Centra%20Abbott%20Dia%203" title="Save Event in my Calendar" >
                  <div className='AgendaInsideCalendar gradientBackground pointer'>Google</div>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://outlook.live.com/calendar/0/deeplink/compose?body=CENTRA%20Abbott%20D%C3%ADa%203%0Ahttps%3A%2F%2Fcentraabbott.com%2F&enddt=2021-07-30T00%3A48%3A00%2B00%3A00&location=https%3A%2F%2Fcentraabbott.com%2F&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2021-07-29T21%3A50%3A00%2B00%3A00&subject=Centra%20Abbott%20Dia%203" title="Save Event in my Calendar" >
                  <div className='AgendaInsideCalendar gradientBackground pointer'>Outlook</div>
                </a>
              </div>
            }
          </div>
          <div>
            <li className='AgendaItem'>
              <p>4:50 pm</p>
              <p>INGRESO A LA PLATAFORMA</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:00 pm</p>
              <p>BIENVENIDA TERCER DÍA</p>
              <p className='AgendaSpeaker'>Oscar Hincapie, MD supervisor de asuntos médicos Latinoamérica</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:05 pm</p>
              <p className='skyColor'>CONFERENCIA #1</p>
              <p className='AgendaTopic'>Retos nutricionales en el manejo de complicaciones gastrointestinales inducidas</p>
              <p className='AgendaSpeaker'>Claudia Maza, ND y Magister en Nutrición</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:30 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>5:45 pm</p>
              <p className='skyColor'>CONFERENCIA #2</p>
              <p className='AgendaTopic'>Ritmos circadianos e implicaciones para la nutrición y la medicina</p>
              <p className='AgendaSpeaker'>Melina Castro, MD Nutriólogo</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:10 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:25 pm</p>
              <p className='skyColor'>CONFERENCIA #3</p>
              <p className='AgendaTopic'>Nutrición y salud Osea</p>
              <p className='AgendaSpeaker'>AnaYanet Jáquez, MD Nutriólogo</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>6:50 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>7:05 pm</p>
              <p className='skyColor'>CONFERENCIA #4</p>
              <p className='AgendaTopic'>Alimentación enteral: Alimentando al paciente críticamente inestable</p>
              <p className='AgendaSpeaker'>Fernando Pereira, MD Cirujano-Especialista en Medicina Crítica y Cuidados intensivos</p>
            </li>
            <hr />
          </div>
          <div>
            <li className='AgendaItem'>
              <p>7:30 pm</p>
              <p>SESIÓN DE PREGUNTAS EN VIVO</p>
            </li>
            <hr />
          </div>
          <div className='AgendaMobileEnd'>
            <p className='whiteColor'>7:45 pm</p>
            <p className='whiteColor'>CIERRE Y DESPEDIDA</p>
            <p className='AgendaSpeaker whiteColor'>Gabriel Gómez, MD Dir. Médico Latinoamérica</p>
          </div>
        </div>
      </ul>
    </div>
  )
}
export default DesktopAgenda