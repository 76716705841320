import { useState } from "react"

import './slide.css'

let SlideCheck = (props) => {
  const { slideCheck: [slideCheck, setSlideCheck] } = { slideCheck: useState(false), ...(props.state || {}) }

  return (
    <label className="switch">
      <input type="checkbox" className="slideCheck" id={props.id} name="checkBox" checked={slideCheck} onChange={e => {setSlideCheck(!slideCheck)}}/>
      <span className="slider round"></span>
    </label>
  )
}

export default SlideCheck