import React, { useState, useEffect } from 'react'
import goldBorder from '../../media/goldBorder.png'

let interval = null

const Counter = () => {
  const [counterValues, setCounterValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [finishDate] = useState(new Date('Jul 27, 2021 16:50:00').getTime())

  useEffect(() => {
    interval = setInterval(() => updateCountdown(), 1000)

    return () => {
      clearInterval(interval)
    }// eslint-disable-next-line
  }, [])

  const updateCountdown = () => {
    if (finishDate) {
      // Get the current time
      const currentTime = new Date().getTime();

      // Get the time remaining until the countdown date
      const distanceToDate = finishDate - currentTime;

      // Calculate days, hours, minutes and seconds remaining
      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      // For visual appeal, add a zero to each number that's only one digit
      const numbersToAddZeroTo = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      }

      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }

      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      // Set the state to each new time
      setCounterValues({ days: days, hours: hours, minutes, seconds });

      if (parseInt(days, 10) <= 0 && parseInt(hours, 10) <= 0 && parseInt(minutes, 10) <= 0 && parseInt(seconds, 10) <= 0) {
        clearInterval(interval)
        setCounterValues({
          days: `00`,
          hours: `00`,
          minutes: `00`,
          seconds: `00`
        })
      }
    }
  }
  return (
    <div className='LandingCounter'>
      <div className='yellowColor'>
        <img src={goldBorder} alt="" />
        <h3 className='thinBold'>{counterValues.days}</h3>
        <img src={goldBorder} alt="" />
        <h4 className='thinBold'>DÍAS</h4>
      </div>
      <hr className='goldColor' />
      <div className='yellowColor'>
        <img src={goldBorder} alt="" />
        <h3 className='thinBold'>{counterValues.hours}</h3>
        <img src={goldBorder} alt="" />
        <h4 className='thinBold'>HORAS</h4>
      </div>
      <hr className='goldColor' />
      <div className='yellowColor'>
        <img src={goldBorder} alt="" />
        <h3 className='thinBold'>{counterValues.minutes}</h3>
        <img src={goldBorder} alt="" />
        <h4 className='thinBold'>MINUTOS</h4>
      </div>
    </div>
  )
}

export default Counter