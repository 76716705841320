import { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Menu from '../../components/menu'
import MobileMenu from '../../components/mobileMenu'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import Slider from './slider/slider'
import SliderRes from './sliderResponsive/sliderRes'
import Loader from 'react-loader-spinner'
import { API } from '../../config'
import Cookies from 'universal-cookie'

import { day27, day28, day29 } from './speakers'

import './memories.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

let Memories = (props) => {
  const cookies = new Cookies();
  let user = cookies.get('user')
  const [selectedIframe, setSelectedIframe] = useState('')
  const [iframeText, setIframeText] = useState('')
  const [loanding, setLoanding] = useState(false)
  const [daySelected, setDaySelected] = useState('day1')
  const [language, setlanguage] = useState('spanish')

  useEffect(() => {
    document.title = 'CENTRA - Memorias'

    return () => { /* Unmount */ }
  }, [])

  useEffect(() => {

    setLoanding(true)

    if (selectedIframe === '') {
      fetch(`${API}settings/D1-C1_spanish`)
        .then(res => res.json())
        .then(res => {
          setLoanding(false)
          setSelectedIframe('')
          if (res.error) {
            setIframeText('<h4>Video no encontrado | Vídeo não encontrado</h4>')
          } else {
            setIframeText(res.settings)
          }
        })
    } else {
      fetch(`${API}settings/${selectedIframe}_${language}`)
        .then(res => res.json())
        .then(res => {
          setLoanding(false)
          if (res.error) {
            if (res.error.split('_')[0].split(' ')[2].trim() === "empty") {
              setIframeText('<h4>Selecciona un video | Selecione um vídeo </h4>')
            } else {
              setIframeText('<h4>Video no encontrado | Vídeo não encontrado</h4>')
            }
          } else {
            setIframeText(res.settings)
          }
        })
    }


  }, [selectedIframe])

  if (user === undefined) {
    return (
      <Redirect to="/"/>
    )
  }

  let selectedDay = (evt, day) => {
    let allBtns = document.getElementsByClassName('titleDek')

    for (let i = 0; i < allBtns.length; i++) {
      if (allBtns[i].classList[1] !== undefined) {
        allBtns[i].className = `titleDek`
      }
    }

    if (evt.nativeEvent.path[1].classList[0] ===  "titleDek") {
      evt.nativeEvent.path[1].className = "titleDek selected"
    }

    setDaySelected(day)
  }

  let selectedLanguage = (evt) => {
    setlanguage(evt)
    setSelectedIframe("empty")
  }

  return (
    <div className="memoriesContainer">
      <header className='memoriesHeader'>
        <img src={Logo1} alt="FELANPE" />
        <Menu />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className="content">
        <div className="logoAndMenu">
          <img src={LogoCentra} alt="Centra" className='memoriesCentralLogo' />
          <MobileMenu />
        </div>
        <div className="container">
          <div className="iframeContainer">
            {
              loanding
              ? <Loader
                  className="loadingCircle"
                  type="TailSpin"
                  color="#ECD589"
                  height={100}
                  width={100}
                />
              : <div className="iframe" dangerouslySetInnerHTML={{ __html: iframeText }}></div>
            }
            <select className='RoomLanguage' onChange={e => {selectedLanguage(e.target.value)}}>
              <option value="default">SELECCIONE EL IDIOMA</option>
              <option value="spanish">ESPAÑOL</option>
              <option value="portuguese">PORTUGUÊS</option>
            </select>
          </div>
          <div className="sliders">
            <h3 className="yellowColor">Memorias</h3>
            <div className="days">

              <div className="actions">
                <div className="titleDek selected" onClick={e => {selectedDay(e, 'day1')}}>
                  <h2>27 Julio</h2>
                  <div className="gradientBackground"></div>
                </div>

                <div className="titleDek" onClick={e => {selectedDay(e, 'day2')}}>
                  <h2>28 Julio</h2>
                  <div className="gradientBackground"></div>
                </div>

                <div className="titleDek" onClick={e => {selectedDay(e, 'day3')}}>
                  <h2>29 Julio</h2>
                  <div className="gradientBackground"></div>
                </div>
              </div>

              <div className="sliderContdesk">
                <Slider dayselected={daySelected} day1={day27} day2={day28} day3={day29} state={{ select: [selectedIframe, setSelectedIframe] }}/>
              </div>
            </div>
            <div className="dayRes">
              <SliderRes day1={day27} day2={day28} day3={day29} state={{ select: [selectedIframe, setSelectedIframe] }}/>
            </div>
            <div className="message">
              <h2></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Memories
