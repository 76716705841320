import React, { useState, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Menu from '../../components/menu'
import MobileMenu from '../../components/mobileMenu'
import './speakers.css'
import Logo1 from '../../media/LogoFelanpe.png'
import Logo2 from '../../media/LogoAbbott.png'
import LogoCentra from '../../media/LogoCentra.png'
import SpekearsSlider from './speakersSlider'
import SpeakersBigSlider from './speakersBigSlider'


const Speakers = () => {
  const [state, setState] = useState(false)
  const [section, setSection] = useState(1)

  document.title = 'CENTRA - Ponentes'

  return (
    <div className='SpeakersContainer'>
      <header className='SpeakersHeader'>
        <img src={Logo1} alt="FELANPE" />
        <Menu />
        <img src={Logo2} alt="Abbott" />
      </header>
      <div className='SpeakersContent'>
        <div className='SpeakersLeft'>
          <div className='AngendaLeftInside'>
            <img src={LogoCentra} alt="Centra" className='SpeakersCentralLogo' />
          </div>
          <MobileMenu />
        </div>
        <div className='SpeakersRight'>
          {state ?
            <SpeakersBigSlider setState={setState} section={section} />
            :
            <Fragment>
              <h2 className='SpeakersTitle yellowColor'>PONENTES INVITADOS</h2>
              <SpekearsSlider setState={setState} setSection={setSection} />
            </Fragment>
          }
        </div>
      </div>
      <div></div>
      <div></div>
    </div>
  )
}

export default withRouter(Speakers)