import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import "./sliderRes.css"

let SliderComp = (props) => {
  const { select: [selected, setSelected] } = { select: useState(''), ...(props.state || {}) }

  var settings = {
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    swipeToSlide: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    dots: false,
  };

  useEffect(() => {


    return () => { /* Unmount */ }
  }, [])

  return (
    <Slider className="slideComponentRes" {...settings}>
      <div className="dayRes1">
        <div className="titleRes">
          <h2>27 Julio</h2>
          <div className="gradientBackground"></div>
        </div>
        <div className="listSpeakers">
          {
            props.day1.map((ele, idx) => {
              return (
                <div className="speakerContRes" key={idx} onClick={e => {setSelected(ele.ref)}}>
                  <h2 className="h2">{ele.conf}</h2>
                  <h3 className="h3">{ele.speaker}</h3>
                  <p className="p">{ele.title}</p>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="dayRes1">
        <div className="titleRes">
          <h2>28 Julio</h2>
          <div className="gradientBackground"></div>
        </div>
        <div className="listSpeakers">
          {
            props.day2.map((ele, idx) => {
              return (
                <div className="speakerContRes" key={idx} onClick={e => {setSelected(ele.ref)}}>
                  <h2 className="h2">{ele.conf}</h2>
                  <h3 className="h3">{ele.speaker}</h3>
                  <p className="p">{ele.title}</p>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="dayRes1">
        <div className="titleRes">
          <h2>29 Julio</h2>
          <div className="gradientBackground"></div>
        </div>
        <div className="listSpeakers">
          {
            props.day3.map((ele, idx) => {
              return (
                <div className="speakerContRes" key={idx} onClick={e => {setSelected(ele.ref)}}>
                  <h2 className="h2">{ele.conf}</h2>
                  <h3 className="h3">{ele.speaker}</h3>
                  <p className="p">{ele.title}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </Slider>
  )
}

export default SliderComp
