import React from 'react'
import leftArrow from '../../media/leftArrow.png'
import rigthArrow from '../../media/rigthArrow.png'
import divLine from './media/divLine.png'
import speaker1 from './media/Ponente 1.png'
import speaker2 from './media/Ponente 2.png'
import speaker3 from './media/Ponente 3.png'
import speaker4 from './media/Ponente 4.png'
import speaker5 from './media/Ponente 5.png'
import speaker6 from './media/Ponente 6.png'
import speaker7 from './media/Ponente 7.png'
import speaker8 from './media/Ponente 8.png'
import speaker9 from './media/Ponente 9.png'
import speaker10 from './media/Ponente 10.png'
import speaker11 from './media/Ponente 11.png'
import './speakersSlider.css'

let currentSection = 1
const sectionNumber = Math.round((window.screen.width / 3) / 150)
let sum = Math.round((window.screen.width / 3) / 150)
let res = Math.round((window.screen.width / 3) / 150)

const nextPage = () => {
  currentSection = currentSection + sum
  sum = sectionNumber
  res = sectionNumber
  while ((currentSection + sum) > 11) {
    sum -= 1
  }
}

const previousPage = () => {
  currentSection = currentSection - res
  sum = sectionNumber
  res = sectionNumber
  while ((currentSection - res) < 1) {
    res -= 1
  }
}

const SpekearsSlider = ({ setState, setSection }) => {
  const changeState = (section) => {
    setState(true)
    setSection(section)
    currentSection = 1
    sum = sectionNumber
    res = sectionNumber
    setTimeout(() => {
      window.location.href = window.location.origin + '/speakers#section' + section
    }, 100);
  }
  return (
    <div className='SpeakersSliderContainer'>
      {currentSection > 1 ?
        <a href={`#section${currentSection - res}`} onClick={previousPage} className='SpeakersSliderButtons yellowColor'>
          <img src={leftArrow} alt="" />
        </a>
        : <div style={{ width: '15px' }} />}
      <div className='SpeakersSlider'>
        <ul className='SpeakersSliderList centraColor bold'>
          <div className='SpeakersSliderSection' id='section1' >
            <img src={speaker1} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Manuel Castillo Zegarra</h2>
                <h4>MD Internista-Nefrólogo</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Nutrición, fragilidad y Enfermedad Renal Crónica</p>
              <button className='yellowColor semiBold' onClick={() => changeState('1')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section2' >
            <img src={speaker2} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Bárbara Valenzuela</h2>
                <h4>MD Nutriólogo</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Metodología de la búsqueda de estudios en nutrición: desde el tipo de estudio hasta análisis de datos</p>
              <button className='yellowColor semiBold' onClick={() => changeState('2')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section3' >
            <img src={speaker3} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Dolores Rodríguez</h2>
                <h4>MD y Magister en Nutrición</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Nutrición de Precisión</p>
              <button className='yellowColor semiBold' onClick={() => changeState('3')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section4' >
            <img src={speaker4} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Olga Gómez Santamaría</h2>
                <h4>MD y Doctorado en Nutrición</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Comorbilidades en Salud Metabólica</p>
              <button className='yellowColor semiBold' onClick={() => changeState('4')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section5' >
            <img src={speaker5} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Daniel Martínez</h2>
                <h4>MD Internista-Geriatra</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Investigación en Pandemia: por qué, qué y cómo</p>
              <button className='yellowColor semiBold' onClick={() => changeState('5')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section6'>
            <img src={speaker6} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Consuelo Velázquez</h2>
                <h4>MD y Magister en Nutrición</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Sarcopenia y fragilidad: Dos comorbilidades desafiantes en enfermedad Hepática Crónica</p>
              <button className='yellowColor semiBold' onClick={() => changeState('6')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section7' >
            <img src={speaker7} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Gabriel Gómez</h2>
                <h4>MD Cirujano Cabeza y Cuello</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Estudio SALUD  Nueva evidencia e impacto de la intervención nutricional</p>
              <button className='yellowColor semiBold' onClick={() => changeState('7')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section8' >
            <img src={speaker8} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Claudia Maza</h2>
                <h4>ND y Magister en Nutrición</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Retos nutricionales en el manejo de complicaciones gastrointestinales inducidas</p>
              <button className='yellowColor semiBold' onClick={() => changeState('8')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section9' >
            <img src={speaker9} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Melina Castro</h2>
                <h4>MD Nutriólogo</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Principios del soporte nuricional en la práctica clínica Ritmos circadianos e implicaciones para la nutrición y la medicina</p>
              <button className='yellowColor semiBold' onClick={() => changeState('9')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section10' >
            <img src={speaker10} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Anayanet Jáquez</h2>
                <h4>MD Nutriólogo</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Nutrición y salud Osea</p>
              <button className='yellowColor semiBold' onClick={() => changeState('10')}>Ver reseña</button>
            </div>
          </div>
          <div className='SpeakersSliderSection' id='section11'>
            <img src={speaker11} alt="" />
            <div className='SpeakersInfo'>
              <div>
                <h2>Fernando Pereira</h2>
                <h4>MD Cirujano-Especialista en Medicina Crítica y Cuidados intensivos</h4>
                <img src={divLine} alt="" />
              </div>
              <p>Alimentación enteral: Alimentando al paciente críticamente inestables</p>
              <button className='yellowColor semiBold' onClick={() => changeState('11')}>Ver reseña</button>
            </div>
          </div>
        </ul>
      </div>
      {currentSection < 11 ?
        <a href={`#section${currentSection + sum}`} onClick={nextPage} className='SpeakersSliderButtons yellowColor'>
          <img src={rigthArrow} alt="" />
        </a>
        : <div style={{ width: '15px' }} />}
    </div>
  )
}

export default SpekearsSlider